import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'index.html', pathMatch: 'full'},
  { path: 'index.html', loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule)},
  { path: 'setup', loadChildren: () => import('./feature/setup/setup.module').then(m => m.SetupModule)},
  { path: '**', redirectTo: '/index.html'},
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule { }
