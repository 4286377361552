import { Component } from '@angular/core';
import { PoMenuItem } from '@po-ui/ng-components';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './shared/services/local-storage/local-storage.service';
import { MingleService, Configuration } from '@totvs/mingle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private mingleService = new MingleService();
  readonly menus: Array<PoMenuItem> = [
    { label: 'Home', action: this.onClick.bind(this) },
  ];

  constructor(private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private _mingleService: MingleService) {
    this._initTranslate();
    // this._initMingleDev();
    // this._initMingleHom();
    this._initMingleProd();
    this._loginMingle();
    // sessionStorage.setItem('outdatedFonts',
    // '{'outdatedFonts':[{'name': 'teste',
    // 'expeditionContinuesDate': 'teste', 'currentDate': '20/20/2020'}]}');
  }

  private onClick(): void { }

  private _initTranslate(): void {
    this.translate.setDefaultLang('pt');
    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use('pt');
    }
  }

  private _initMingleDev(): void {
    const config = new Configuration();
    config.app_identifier = '60df15aa8c7cbf14e20a0ee0';
    config.environment = 'DEV';
    config.server = 'https://dev-mingle.totvs.com.br/api';
    config.modules.usage_metrics = true;
    config.modules.web = true;
    this.localStorageService.set('Environment', 'DEV');
    this.mingleService.setConfiguration(config);
  }

  private _initMingleHom(): void {
    const config = new Configuration();
    config.app_identifier = '60e3365f9d223055d6bad046';
    config.environment = 'HOM';
    config.server = 'https://hom-mingle.totvs.com.br/api';
    config.modules.usage_metrics = true;
    config.modules.web = true;
    this.localStorageService.set('Environment', 'HOM');
    this.mingleService.setConfiguration(config);
  }

  private _initMingleProd(): void {
    // tslint:disable-next-line:no-console
    console.log('ACM init');
    const config = new Configuration();
    config.app_identifier = '60e34bbb259b42b4790e6d57';
    config.environment = 'PROD';
    config.server = 'https://mingle.totvs.com.br/api';
    config.modules.usage_metrics = true;
    config.modules.web = true;
    this.localStorageService.set('Environment', 'PROD');
    this.mingleService.setConfiguration(config);
  }

  private _loginMingle(): void {
    this._mingleService.init();
  }
}
